import { seoApi } from '~/api/seo'
import { RESPONSE_STATUS } from '~/config/constant'

interface ResponseAPI {
  code: number,
  data: {
    gtm_id: string | string[],
    google_site_verification: string | string[],
    meta_pixel_id: string | string[],
    domain: string,
    favicon: string,
    logo: string,
    favicon_16_16: string,
    favicon_32_32: string,
    apple_touch_icon: string
  }
}

export default defineNuxtPlugin(async (nuxtApp) => {
  const headers = useRequestHeaders()
  const { $config } = nuxtApp
  const { BRAND_INFO } = seoApi()
  const { data } = await useAsyncData<ResponseAPI>('gtm-status', () =>
    $fetch(`${$config.API_BASE_URL}${BRAND_INFO}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        cookie: `brand_code=${$config.BRAND_CODE}; host=${headers.host}`
      }
    })
  )

  if (data.value?.code === 200) {
    const gtmId = (data.value?.data?.gtm_id as string[])?.join?.(',') ?? data.value?.data?.gtm_id
    const googleSiteVerification = (data.value?.data?.google_site_verification as string[])?.join?.(',') ?? data.value.data.google_site_verification
    const metaPixelId = (data.value?.data?.meta_pixel_id as string[])?.join?.(',') ?? data.value.data.meta_pixel_id

    const headConfig: any = {
      script: [
        {
          innerHTML: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': 
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0], 
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src= 
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f); 
          })(window,document,'script','dataLayer','${gtmId}');`
        }
      ],
      meta: [
        {
          name: 'google-site-verification',
          content: googleSiteVerification
        }
      ],
      noscript: [
        {
          tagPosition: 'bodyOpen',
          innerHTML: `<iframe src="https://www.googletagmanager.com/ns.html?id=${gtmId}" height="0" width="0" style="display:none;visibility:hidden"></iframe>`
        }
      ]
    }

    // Add script and noscript when metaPixelId is exists
    if (metaPixelId) {
      headConfig.script.push({
        innerHTML: `!function(f,b,e,v,n,t,s)
          {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
          n.callMethod.apply(n,arguments):n.queue.push(arguments)};
          if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
          n.queue=[];t=b.createElement(e);t.async=!0;
          t.src=v;s=b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t,s)}(window, document,'script',
          'https://connect.facebook.net/en_US/fbevents.js');
          fbq('init', '${metaPixelId}');
          fbq('track', 'PageView');`
      })

      headConfig.noscript.push({
        innerHTML: `<img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=${metaPixelId}&ev=PageView&noscript=1" />`
      })
    }

    // Ghi chú: chú ý khi merge source không xoá bỏ vì chỉ làm cho branch master
    if ($config.public.APP_ENV === 'PROD') {
      headConfig.script.push({
        tagPosition: 'head',
        innerHTML: `(function(h,o,t,j,a,r){
          h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
          h._hjSettings={hjid:6366225,hjsv:6};
          a=o.getElementsByTagName('head')[0];
          r=o.createElement('script');r.async=1;
          r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
          a.appendChild(r);
        })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`
      })
    }

    useHead(headConfig)
  }
})
