<template>
  <img
    v-if="!errorImg"
    :src="fullSrc()"
    :alt="dataAlt"
    v-bind="$attrs"
    :width="width"
    :height="height"
    crossorigin="anonymous"
    :style="{ borderRadius: borderRadius && `${borderRadius}px` }"
    @error="showAltImage"
  />
</template>

<script setup lang="ts">
const { $config } = useNuxtApp()

const props = defineProps({
  showDefault: {
    type: Boolean,
    default: false
  },
  width: {
    type: String,
    default: undefined
  },
  height: {
    type: String,
    default: undefined
  },
  borderRadius: {
    type: String,
    default: undefined
  },
  lazy: {
    type: Boolean,
    default: false
  },
  src: {
    type: String,
    default: ''
  },
  alt: {
    type: String,
    default: ''
  },
  relativeSrc: {
    type: String,
    default: ''
  },
  defaultSrc: {
    type: String,
    default: ''
  }
})
const isLoaded = ref(!props.lazy)
const errorImg = ref(false)
const fullSrc = () => {
  return props.relativeSrc !== ''
    ? props.relativeSrc
    : (!/^(\s?)http(s?):\/\//.test(props.src) && !/^\/\//.test(props.src)
        ? `${new URL(props.src, $config.STATIC_BASE_URL).toString()}?v=${$config.public.APP_VERSION}`
        : props.src)
}
const showAltImage = (event: Event) => {
  if (props.showDefault) {
    errorImg.value = true
  }
  const target = event.target as HTMLImageElement
  const fallbackSrc = props.defaultSrc || '/assets/brand/logo.webp'
  const absoluteFallbackSrc = props.defaultSrc ? new URL(fallbackSrc, $config.STATIC_BASE_URL).toString() : fallbackSrc
  if (target.src !== absoluteFallbackSrc) {
    target.src = fallbackSrc
  }
}
const dataAlt = computed(() => {
  return props.alt ? `${props.alt} ${$config.public.BRANCH_NAME}` : $config.public.BRANCH_NAME
})

const route = useRoute()
watch(route, () => {
  errorImg.value = false
})

onMounted(() => {
  if (!props.src) {
    return
  }

  if (!props.lazy) {
    isLoaded.value = true
  }

  const img = new Image()
  img.setAttribute('crossorigin', 'anonymous')
  img.src = fullSrc()
  img.onload = (evt) => {
    setTimeout(() => {
      isLoaded.value = true
    }, 100)
  }
})
</script>
<style lang="scss" scoped src="~/public/assets/scss/components/common/base-img.scss"></style>
