// Imports: external libraries first, then project-specific constants
import round from 'lodash/round'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import dayjs from 'dayjs'
import CryptoJS from 'crypto-js'
import { DATE_FORMATS } from '~/config/constant'

// Extend dayjs with plugins
dayjs.extend(utc)
dayjs.extend(timezone)

// Number formatting utilities
export const formatNumber = (value: number, suffix: string) => `${Intl.NumberFormat('en-US').format(value)} ${suffix}`

export const numberWithCommas = (x: string, suffix = '.') => x.replace(/\B(?=(\d{3})+(?!\d))/g, suffix)

export const roundFloatNumber = (number: number, precision: number) => round(number, precision)

// Encryption utilities
const PASSPHRASE = 'BK_PASSPHRASE'

export const encryptWithAES = (value: string) => CryptoJS.AES.encrypt(value, PASSPHRASE).toString()

export const decryptWithAES = (cipherText: string) =>
  CryptoJS.AES.decrypt(cipherText, PASSPHRASE).toString(CryptoJS.enc.Utf8)

// String utilities
export const normalizeDiacritics = (str = '') => {
  const accentsMap = [
    { regex: /[àáạảãâầấậẩẫăằắặẳẵ]/g, replacement: 'a' },
    { regex: /[èéẹẻẽêềếệểễ]/g, replacement: 'e' },
    { regex: /[ìíịỉĩ]/g, replacement: 'i' },
    { regex: /[òóọỏõôồốộổỗơờớợởỡ]/g, replacement: 'o' },
    { regex: /[ùúụủũưừứựửữ]/g, replacement: 'u' },
    { regex: /[ỳýỵỷỹ]/g, replacement: 'y' },
    { regex: /đ/g, replacement: 'd' },
    { regex: /[\u0300\u0301\u0303\u0309\u0323]/g, replacement: '' }, // Huyền, sắc, hỏi, ngã, nặng
    // eslint-disable-next-line no-misleading-character-class
    { regex: /[\u02C6\u0306\u031B]/g, replacement: '' } // Â, Ê, Ă, Ơ, Ư
  ]
  let normalizedStr = str.trim().toLowerCase()
  accentsMap.forEach(({ regex, replacement }) => {
    normalizedStr = normalizedStr.replace(regex, replacement)
  })
  return normalizedStr
}

export const trimExtraSpaces = (word = '') => word.trim().replace(/\s\s+/g, ' ')

// Date-time formatting with timezone support
export const formatDateTimeWithTimezone = (
  dateTime: string | number | dayjs.Dayjs | Date | null | undefined,
  format = 'HH:mm DD/MM/YYYY',
  timezone = 'Asia/Ho_Chi_Minh'
) => dayjs.utc(dateTime, DATE_FORMATS.FULL_DATE_FORMAT).tz(timezone).format(format)

// User agent patterns
const BOT_PATTERN = /(googlebot\/|bot|...|lipperhey|yoozBot|xovibot|archive\.org_bot|Applebot|TweetmemeBot)/i
const LIGHTHOUSE_PATTERN = /(googlebot\/|bot|Chrome-Lighthouse)/i

export const isCacheCrawlerAgent = (userAgent: string): boolean => BOT_PATTERN.test(userAgent)

export const isBotChromeLighthouse = (userAgent: string): boolean => LIGHTHOUSE_PATTERN.test(userAgent)

// URL validation
export const isValidURL = (str: string) => {
  const pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
    '(([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
    '(\\#[-a-z\\d_]*)?$',
    'i'
  )
  return pattern.test(str)
}

// Contact information handling
export const getContactValueFromEnv = (
  contacts: any[],
  tele: string,
  phone: string,
  email: string,
  telegramCSKH: string
) =>
  contacts.map((item) => ({
    ...item,
    text: item.tag === 'telegram' ? tele : item.tag === 'phone' ? phone : item.tag === 'email' ? email : telegramCSKH
  }))

// Replace domain name placeholders
export const replaceDomainName = (value = '', branchName = '') => value.replace('{{domain_name}}', branchName)

// Remove duplicates from an array
export const removeDuplicates = (list = [], keyName: string | number) => {
  const uniqueItems = {}
  list.forEach((item) => {
    if (!uniqueItems[item[keyName]]) {
      uniqueItems[item[keyName]] = item
    }
  })
  return Object.values(uniqueItems)
}

export const isExternalIcon = (src: string): boolean => {
  return /^(\s?)http(s?):\/\//.test(src) || /^\/\//.test(src)
}

// Utility function to remove accents
const removeAccents = (str: string) => str.normalize('NFD').replace(/[\u0300-\u036F]/g, '')

// Search function
export const searchVietnamese = (data: any[], searchTerm: string) => {
  const normalizedSearchTerm = normalizeDiacritics(searchTerm.toLowerCase())
  return data.filter((item) => {
    const normalizedItemText = normalizeDiacritics(item.text.toLowerCase())
    return normalizedItemText.includes(normalizedSearchTerm)
  })
}
