export const schemaSeoConfig = ($domainUrl: string, $imageUrl: string) => [
  JSON.stringify(
    {
      '@context': 'https://schema.org',
      '@type': 'LocalBusiness',
      name: 'Trang Web chính thức của GA88 - Top 1 Nhà Cái Thể Thao, Bóng Đá, Live Casino, Đá Gà',
      brand: 'GA88',
      legalName: 'Nhà cái GA88', //  Không tìm thấy tên pháp lý chính thức, nên dùng tên thương hiệu
      slogan: 'Cược Đỉnh Cao - Bùng Nổ Cùng Siêu Chợ Games',
      additionalType: [
        'https://vi.wikipedia.org/wiki/B%C3%B3ng_%C4%91%C3%A1',
        'https://vi.wikipedia.org/wiki/C%C3%A1_c%C6%B0%E1%BB%A3c_th%E1%BB%83_thao',
        'https://vi.wikipedia.org/wiki/S%C3%B2ng_b%C3%A0i'
      ],
      knowsLanguage: 'Vietnamese',
      logo: `${$imageUrl}/assets/brand/logo.webp`,
      image: `${$imageUrl}/assets/brand/seo-image.webp?h=${generateHash(3)}`, // Provide an image URL
      '@id': `${$domainUrl}/`,
      url: `${$domainUrl}/`,
      mainEntityOfPage: `${$domainUrl}/`,
      description: 'Trang chủ chính thức của nhà cái GA88 - Nhà cái đẳng cấp với hệ thống cược Đá Gà kịch tính, cùng Thể Thao, Live Casino và Quay Số.  Cam kết tỷ lệ cược vượt trội, thưởng hấp dẫn, nạp rút siêu tốc và hơn 12,000+ game.',
      paymentAccepted: 'Codepay,P2P USDT, Momo,Viettel Money,Thẻ cào',
      disambiguatingDescription: 'Nhà cái GA88 cung cấp đa dạng trò chơi cá cược online, bao gồm cá cược thể thao, đá gà, live casino, quay số, nổ hũ, lô đề, game bài, bắn cá, và slots với hơn 12,000+ lựa chọn từ các nhà cung cấp hàng đầu.',
      knowsAbout: 'Cá cược thể thao, Đá gà, Live Casino, Quay số, Nổ hũ, Lô đề, Game bài, Bắn cá, Slots',
      foundingDate: '2023',
      areaServed: {
        '@type': 'Country',
        name: 'Vietnam'
      },
      telephone: '+84935464888',
      priceRange: '$',
      address: {
        '@type': 'PostalAddress',
        streetAddress: 'số 8 Thăng Long, Phường 4, Tân Bình, thành phố Hồ Chí Minh',
        addressLocality: 'Hồ Chí Minh',
        postalCode: '700000',
        addressCountry: 'VN'
      },
      geo: {
        '@type': 'GeoCoordinates',
        latitude: '10.801860',
        longitude: '106.660763'
      },
      openingHoursSpecification: {
        '@type': 'OpeningHoursSpecification',
        dayOfWeek: [
          'Monday',
          'Tuesday',
          'Wednesday',
          'Thursday',
          'Friday',
          'Saturday',
          'Sunday'
        ],
        opens: '00:00',
        closes: '23:59'
      },
      sameAs: [
        'https://t.me/CSKHGA88',
        'https://www.tiktok.com/@ga88thethao',
        'https://www.instagram.com/ga88official/',
        'https://www.facebook.com/ga88official/',
        'https://www.youtube.com/@GA88official',
        'https://www.threads.net/@ga88official',
        'https://t.me/ga88nhacaithethao'
      ]
    }
  )
]

function generateHash(length: number) {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  let hash = ''

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length)
    hash += characters[randomIndex]
  }

  return hash
}
