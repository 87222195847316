import plugin_vue3_A0OWXRrUgq from "/app/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import unhead_KgADcZ0jPj from "/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import prefetch_client_5tzzN0oIVL from "/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_t2GMTTFnMT from "/app/node_modules/@nuxtjs/device/dist/runtime/plugin.mjs";
import templates_axios_plugin_mncGH1WTAw_9dwno3euKI from "/app/.nuxt/templates.axios.plugin.mncGH1WTAw.mjs";
import chunk_reload_client_UciE0i6zes from "/app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import _1_multiTabState_client_1CjJExz7xX from "/app/plugins/1.multiTabState.client.ts";
import _2_user_agent_OU0q22bMEh from "/app/plugins/2.user-agent.ts";
import _3_useEmitter_client_YNkJHVTAl5 from "/app/plugins/3.useEmitter.client.ts";
import axios_QMFgzss1s4 from "/app/plugins/axios.ts";
import device_PxhEqcDcOg from "/app/plugins/device.ts";
import init_store_OEoVDTOPd9 from "/app/plugins/init.store.ts";
import global_2XP5sjpyNs from "/app/plugins/global.ts";
import inject_aKvMu3yXrx from "/app/plugins/inject.ts";
import bootstrap_client_Uyd73t1ylZ from "/app/plugins/bootstrap.client.ts";
import router_scrollBehavior_qxiobKO0L0 from "/app/plugins/router.scrollBehavior.ts";
import vue_dompurify_html_ER8SW1XOdk from "/app/plugins/vue-dompurify-html.ts";
import gtm_api_check_YvgJ4xRCGt from "/app/plugins/gtm-api-check.ts";
import init_app_bV60E7c94l from "/app/plugins/init.app.ts";
import maintain_bSXIRgwRMa from "/app/plugins/maintain.ts";
import match_detail_vnpsylLozq from "/app/plugins/match-detail.ts";
import vue_sanitize_b6XA76Qotp from "/app/plugins/vue-sanitize.ts";
export default [
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  prefetch_client_5tzzN0oIVL,
  plugin_t2GMTTFnMT,
  templates_axios_plugin_mncGH1WTAw_9dwno3euKI,
  chunk_reload_client_UciE0i6zes,
  _1_multiTabState_client_1CjJExz7xX,
  _2_user_agent_OU0q22bMEh,
  _3_useEmitter_client_YNkJHVTAl5,
  axios_QMFgzss1s4,
  device_PxhEqcDcOg,
  init_store_OEoVDTOPd9,
  global_2XP5sjpyNs,
  inject_aKvMu3yXrx,
  bootstrap_client_Uyd73t1ylZ,
  router_scrollBehavior_qxiobKO0L0,
  vue_dompurify_html_ER8SW1XOdk,
  gtm_api_check_YvgJ4xRCGt,
  init_app_bV60E7c94l,
  maintain_bSXIRgwRMa,
  match_detail_vnpsylLozq,
  vue_sanitize_b6XA76Qotp
]